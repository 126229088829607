module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pl","ua"],"defaultLanguage":"pl","siteUrl":"https://sasiedzkapomoc.netto.pl/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/","excludeLanguages":["pl"]},{"matchPath":"/:lang?/","getLanguageFromPath":true,"languages":["ua"]},{"matchPath":"/zgloszenie-przyjete","excludeLanguages":["pl"]},{"matchPath":"/:lang?/zgloszenie-przyjete","getLanguageFromPath":true,"languages":["ua"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Netto Sąsiedzka Pomoc","short_name":"Netto Sąsiedzka Pomoc","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/assets/img/favicon48x48.png","icons":[{"src":"/favicon48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicon32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicon16x16.png","sizes":"16x16","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"77c842c260780c51a08d2040a9329bca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://sasiedzkapomoc.netto.pl/","stripQueryString":true},
    }]
